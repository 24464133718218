@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;

  /* overflow-x: hidden; */
}

body {
  @apply text-textColor;
  /* @apply bg-custom-img bg-cover bg-center bg-no-repeat overflow-y-auto ; */
}

@media (max-width: 850px) {
  body {
    background-attachment: scroll;
  }
}

@media (min-width: 851px) {
  body {
    background-attachment: fixed;
  }
}

.oblique-border {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom right, transparent 50%, blue 50%);
}

.perspective-1000 {
  perspective: 1000px;
}
.backface-visibility-hidden {
  backface-visibility: hidden;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}

.hexagon-img {
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.ellipse-img {
  border-radius: 5rem 0 5rem 0;
}
.ellipse-img img {
  transition: transform 0.3s ease-in-out;
}

.ellipse-img img:hover {
  transform: scale(1.1);
}

.loader {
  border-top-color: #3498db;
  animation: spinner 1s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in {
  animation: slideIn 1s ease-out forwards;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
  animation: slideInFromLeft 1s ease-out forwards;
}

.slide-in-right {
  animation: slideInFromRight 1s ease-out forwards;
}

* {
  box-sizing: border-box;
}

.bg-opacity-custom {
  position: relative;
  z-index: 0;
}

.bg-opacity-custom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/src/images/logoTopContact.webp");
  background-repeat: repeat;
  background-position: center;
  opacity: 0.1;
  z-index: -1;
}

.clip-hexagon {
  @apply relative overflow-hidden w-60 h-60 mx-auto;
  clip-path: polygon(
    25% 6.7%,
    75% 6.7%,
    100% 50%,
    75% 93.3%,
    25% 93.3%,
    0% 50%
  );
}

.clip-hexagon img {
  @apply absolute top-0 left-0 w-full h-full object-cover;
}

.image-gallery-thumbnails-wrapper {
  display: flex;
  align-items: center;
  justify-items: center;
}

.image-gallery-thumbnail img {
  object-fit: cover;
  width: auto;
  height: auto;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.bounce-effect {
  animation: bounce 2s infinite;
}

.flip-card {
  background-color: transparent;
  perspective: 62.5rem;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 15rem;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}

.flip-card-back::-webkit-scrollbar {
  width: 8px;
}

.flip-card-back::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0.5rem;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.flip-card-front {
  background-color: #fff;
  color: black;
  border-radius: 0.5rem;
}

.flip-card-back {
  background-color: rgba(120, 120, 120, 1);
  color: white;
  transform: rotateY(180deg);
  border-radius: 0.5rem;
}

/* Initially hidden and off-screen */
.banner-hidden {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

/* Visible banner that slides into place */
.banner-visible {
  animation: slideInFromLeft 0.7s forwards,
    alternateBackground 5s ease-in-out infinite;
  opacity: 1;
  transform: translateX(0); /* Return to its original position */
}

button {
  cursor: pointer;
}

.quiz-answer {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.quiz-answer.hidden {
  opacity: 0;
  display: none;
}

.quiz-item button:hover {
  color: rgb(40, 56, 144);
  text-decoration: underline;
}





/* Optional: Add this in your CSS file if not using Tailwind */
.transition-transform {
  transition: transform 1s ease-in-out;
}


